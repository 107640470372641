import React from 'react';
import { isUserPremium } from '../../util/data';
import { ExternalLink, IconPremium, IconVideo } from '../../components';

import css from './ListingPage.module.css';

const SectionBadges = props => {
  const { publicData, currentAuthor } = props;

  const isPremiumListing = isUserPremium(currentAuthor);
  // Pull video link from publicData
  // and add https protocol to it
  const videoLink = publicData?.videoLink;
  const videoURL = `https://${videoLink}`;

  const showSection = isPremiumListing || videoLink;
  return showSection ? (
    <div className={css.sectionBadges}>
      {isPremiumListing ? (
        <div className={css.premiumContainer}>
          <IconPremium />
        </div>
      ) : null}
      {videoLink ? (
        <ExternalLink href={videoURL} className={css.videoLinkContainer}>
          <IconVideo />
        </ExternalLink>
      ) : null}
    </div>
  ) : null;
};

export default SectionBadges;
