import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, IconSocialMediaFacebook, IconSocialMediaInstagram } from '../../components';

import css from './ListingPage.module.css';

const socialLink = (name, link) => {
  switch (name) {
    case 'facebook':
      return (
        <ExternalLink key="facebook" href={`https://facebook.com/${link}`} className={css.social}>
          <IconSocialMediaFacebook className={css.socialIcon} />
          <p className={css.socialLink}>{link}</p>
        </ExternalLink>
      );

    case 'instagram':
      return (
        <ExternalLink key="instagram" href={`https://instagram.com/${link}`} className={css.social}>
          <IconSocialMediaInstagram className={css.socialIcon} />
          <p className={css.socialLink}>{link}</p>
        </ExternalLink>
      );
  }
};

const SectionSocialsMaybe = props => {
  const { publicData } = props;

  const socials = publicData?.socials;
  const hasSocials = socials && Object.keys(socials).length > 1;

  return hasSocials ? (
    <div className={css.sectionSocials}>
      <h2 className={css.socialsTitle}>
        <FormattedMessage id="ListingPage.socialsTitle" />
      </h2>
      <div className={css.socials}>
        {Object.keys(socials).map(s => {
          const name = s;
          const link = socials[s];
          return socialLink(name, link);
        })}
      </div>
    </div>
  ) : null;
};

export default SectionSocialsMaybe;
