import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { InlineTextButton } from '../../components';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

export const BASKETBALL_TRAINER = 'basketball-trainer';
export const WEIGHT_TRAINER = 'weight-trainer';
export const RECOVERY_SNAKS = 'recovery-snacks';
export const MEDIA_DAY = 'media-day';

const serviceText = (intl, serviceKey) => {
  const [showMediaDayList, setShowMediaDayList] = React.useState(false);

  let key = null;
  if (serviceKey === BASKETBALL_TRAINER) {
    key = 'ListingPage.basketballTrainerServiceText';
  } else if (serviceKey === WEIGHT_TRAINER) {
    key = 'ListingPage.weightTrainerServiceText';
  } else if (serviceKey === RECOVERY_SNAKS) {
    key = 'ListingPage.recoverySnaksServiceText';
  } else if (serviceKey === MEDIA_DAY) {
    return (
      <ul className={css.additionalServiceList}>
        <li className={css.additionalServiceText}>
          1 on 1 or group basketball training session with a professional basketball trainer.
        </li>
        <li className={css.additionalServiceText}>High quality video footage of training</li>
        {showMediaDayList ? (
          <>
            <li className={css.additionalServiceText}>A haircut by a professional barber</li>
            <li className={css.additionalServiceText}>Player profile photo-shots</li>
            <li className={css.additionalServiceText}>Social Media Exposure</li>
            <li className={css.additionalServiceText}>Social Media Content</li>
            <li className={css.additionalServiceText}>
              Connecting players to global professional basketball teams & agents
            </li>
          </>
        ) : (
          <InlineTextButton
            className={css.additionalServiceText}
            onClick={() => setShowMediaDayList(!showMediaDayList)}
          >
            Show more
          </InlineTextButton>
        )}
      </ul>
    );
  }

  return intl.formatMessage({ id: key });
};

const SectionAdditionalServices = props => {
  const { intl, publicData } = props;

  const additionalServices = publicData?.additionalServices;
  const hasAdditionalServices = additionalServices?.length > 0;
  return hasAdditionalServices ? (
    <div className={css.sectionAdditionalServices}>
      <h2 className={css.additionalServicesTitle}>
        <FormattedMessage id="ListingPage.additionalServicesTitle" />
      </h2>
      <div className={css.additionalServices}>
        {additionalServices.map(service => {
          const formattedMoney = new Money(service.price.amount, service.price.currency);
          const formattedPrice = formatMoney(intl, formattedMoney);
          return (
            <div key={service.key} className={css.additionalService}>
              <h3 className={css.additionalServiceTitle}>{service.longLabel || service.label}</h3>
              <p className={css.additionalServiceText}>{serviceText(intl, service.key)}</p>
              <div className={css.additionalServicePrice}>{formattedPrice}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SectionAdditionalServices;
