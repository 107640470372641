import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionEquipmentMaybe = props => {
  const { publicData } = props;

  const mainEquipment = publicData?.mainEquipment;
  return mainEquipment ? (
    <div className={css.sectionEquipment}>
      <h2 className={css.equipmentTitle}>
        <FormattedMessage id="ListingPage.equipmentTitle" />
      </h2>
      <p className={css.equipment}>{mainEquipment}</p>
    </div>
  ) : null;
};

export default SectionEquipmentMaybe;
